*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.leaderboardpage{
    min-height: 100vh;
    width: 100%;
    background-image: url("./../../resources/sprites/Background-min-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leaderboard-container{
    background-image: url("./../../resources/sprites/text-bg-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80%;
    max-width: 700px;
    height: 70vh;
    aspect-ratio: 2/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    padding: 5rem 2rem;
    margin-top: 4rem;
}

.leaderboard-table {
    width: 80%;
}
/* table, td, th {
    border: 3px solid black;
    border-style: solid;
    border-collapse: collapse;
} */

table {
    border-spacing: 5px;
}

th {
    font-size: 20px;
}

td, th {
    padding: 15px;
    margin: 5px;
    background-color: white;
    border-radius: 20px;
}

.logo{
    text-align: center;

}
.logo img {
    max-width: 250px;
    margin-bottom: 1rem;
}

h2{
    min-width: 30%;
    margin-right: 1rem;
    color: #192F56;
}