* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.scenario1 {
    min-height: 100vh;
    width: 100%;
    background-image: url("./../../resources/sprites/Background-min-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #192F56;
}

.logo {
    text-align: center;

}

.logo img {
    max-width: 250px;
    margin-bottom: 1rem;
}

h1{
    text-align: center;
    color: #192F56;
    font-size: 1.5rem;
}

.text-center {
    text-align: center;
}



.scenario-container {
    background-image: url("./../../resources/sprites/text-bg-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
   /*  background-color: rgb(181, 236, 247);
    border-radius: 40px;
    border: 5px solid palevioletred; */
    width: 80%;
    max-width: 700px;
    /* min-height: 70vh; */
    /* min-height: fit-content; */
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem;
    padding-top: 5rem;
    margin-top: 4rem;
}

h1{
    font-size: 2rem;
}

.text-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bio-img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    display: grid;
    place-items: center;
}
.bio-img img{
    max-width: 100px;
    max-height: 100px;
}

.scenario-container h2{
    margin-bottom: 1rem;

}

.scenario-container p:nth-child(1),
.scenario-container p:nth-child(2){
    margin-top: 1rem;
}

.alltext{
    text-align: start;
}

p span{
    color: palevioletred;
    font-weight: bold;
}

h3{
    margin: 2rem 0;
}

.scenario-1-img {
    width: 80%;
}

.scenario-1-p {
    margin-top: 25px;
    font-size: 35px;
}
.scenario-2-img {
    width: 15%;
}

.scenario-2-img-arrow {
    margin-top: 10px;
    width: 9%;
    /* height: 90px; */
}
.scenario-2-title {
    margin-top: 10px;
    color: #C99617;
}

.scenario-2-p {
    margin-top: 25px;
    font-size: 30px;
}

.scenario-3-title {
    margin-top: 10px;
    color: #C99617;
}
.scenario-3-body {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scenario-3-img {
    max-width: 90%;
    max-height: 90%;
}
.scenario-4-img {
    margin-top: 15px;
    max-width: 80%;
    max-height: 80%;
}
.scenario-4-p {
    margin-top: 25px;
    font-size: 24px;
}

.scenario-1-btn, .scenario-2-btn, .scenario-3-btn, .scenario-4-btn{
    margin-top: 3rem;
    min-width: 100px;
    min-height: 70px;
    text-align: start;
    background-color: transparent;
    background-image: url("./../../resources/sprites/Arrow_long.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: auto;
    color:#ffffff;
    font-weight: bold;
    border-radius: 20px;
    border: none;
    padding: .5rem;
    display: flex;
    border: none;
    padding: 0.5rem;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    position: absolute;
    bottom: 20px;
    right: 40px;
}
/*
@media screen and (max-width: 550px){
    .scenario-1-p .scenario-2-p {
        font-size: 24px;
    }
} */