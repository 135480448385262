*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.formpage{
    min-height: 100vh;
    width: 100%;
    background-image: url("./../../resources/sprites/Background-min-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo{
    text-align: center;

}

.logo img{
    max-width: 400px;
}

.text-center{
    text-align: center;
    color: #192F56;
    margin: 0;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;

}

.text-container{
    max-inline-size: 65ch;
}

.text-center h2{
    color: #192F56;
    margin: 1rem 0;
}

.text-container .form-page-first-paragraph {
    margin-bottom: 15px;
}

.text-container p:nth-child(2){
    font-weight: bold !important;
}

.form-container{
    background-image: url("./../../resources/sprites/text-bg-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 80%;
    max-width: 700px;
    height: 50vh;
    aspect-ratio: 2/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2rem;
    margin-top: 4rem;
}

.leaderboardBtn {
    position: fixed;
    right: 20px;
    top: 20px;
}

.bio-img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    display: grid;
    place-items: center;
}

.bio-img img {
    max-width: 100px;
    max-height: 100px;
}

.logo img {
    max-width: 250px;
    margin-bottom: 1rem;
}

.inputs{
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: flex-end;
}

.input-wr{
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-between;

}

.errorField {
    outline: red 3px solid;
}

.formErrorMsg {
    color: red;
}

input:not([type="checkbox"]){
    border: none;
    width: 70%;
}
h2{
    min-width: 30%;
    margin-right: 1rem;
    color: #192F56;
}

.footerCtn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.privacyPolicyCtn {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.privacyPolicyCtn > input {
    width: 15px;
    height: 15px;
}

.privacyPolicyCtn > label {
    margin-right: 5px;
}

.loginBtn {
    margin-bottom: 10px;
    margin-left: auto;
}

.toScenarioBtn {
    height: 40px;
    min-width: 40%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.toScenarioBtn, .loginBtn {
background-image: url("./../../resources/sprites/button-lil.png");
background-size: contain;
background-repeat: no-repeat;
background-size: 100% 100%;
/* margin-left: auto; */
color:white;
border-radius: 20px;
border: none;
padding: .5rem;
}
