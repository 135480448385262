.gamepage {
    min-height: 100vh;
    width: 100%;
    background-image: url("./../../resources/sprites/Background-min-3.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.gamepage-loading{
    min-height: 100vh;
    width: 100%;
    background-image: url("./../../resources/sprites/Background-min-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}